import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";

import { InfoIcon } from "./icons/InfoIcon";

const SESSION_KEY = "noticeDismissed";
const CT_TZ = "America/Chicago";
const NOTICE_START = "2024-11-14 0:00";
const NOTICE_END = "2024-11-25 2:00";

export function MaintenanceNotice() {
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(true);

  const currDate = moment().tz(CT_TZ);
  const noticeStart = useMemo(() => moment.tz(NOTICE_START, CT_TZ), []);
  const noticeEnd = useMemo(() => moment.tz(NOTICE_END, CT_TZ), []);

  useEffect(() => {
    const previouslyDismissed = window.sessionStorage.getItem(SESSION_KEY);

    if (previouslyDismissed === "true") {
      setShouldDisplay(false);
    }
  }, []);

  useEffect(() => {
    const currDateInRange = currDate > noticeStart && currDate < noticeEnd;
    if (!currDateInRange) {
      setShouldDisplay(false);
    }
  }, [currDate, noticeStart, noticeEnd]);

  const onDismiss = useCallback(() => {
    setShouldDisplay(false);
    window.sessionStorage.setItem(SESSION_KEY, "true");
  }, []);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <aside className="bg-bidFlash">
      <div className="flex gap-2 p-4 max-w-page-content mx-auto">
        <p>
          <InfoIcon className="inline-block -mt-1 mr-2 text-primaryText" />
          Notice: we have an upcoming maintenance window on 11/25 which may
          result in a loss of external connection to your servers between
          12:30AM CT - 1:30am CT. Please contact support with any further
          questions or help.
        </p>
        <div>
          <button
            className="block group flex items-center justify-center rounded-full p-1 border border-black hover:bg-black w-6 h-6"
            onClick={onDismiss}
          >
            <span className="group-hover:text-bidFlash text-xs">X</span>
          </button>
        </div>
      </div>
    </aside>
  );
}
